import React from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { useRouter } from "next/router";
import { Calendar, Handshake, MessagesSquare, PartyPopper, ScrollText, TrendingUp } from "lucide-react";
import { FaChartColumn } from "react-icons/fa6";

const HomeScreen: React.FC = () => {
  const router = useRouter();
  return (
    <div className="mt-24 flex w-screen justify-center">
      <div className="flex items-center">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <Card className="bg-gray-200">
            <CardHeader>
              <CardTitle className="text-center text-3xl font-bold">Venue Concierge</CardTitle>
              <CardDescription className="text-center text-lg">If you're looking for the perfect spot</CardDescription>
            </CardHeader>
            <CardContent>
              <li className="mb-4 flex items-center space-x-4">
                <Calendar className="text-darkpurple h-8 w-8 flex-shrink-0" />
                <h3 className="text-xl font-semibold">Tell us about your event</h3>
              </li>
              <p className="mb-8">Take a couple minutes to tell us all your needs.</p>

              <li className="mb-4 flex items-center space-x-4">
                <ScrollText className="text-darkpurple h-8 w-8 flex-shrink-0" />
                <h3 className="text-xl font-semibold">Receive a curated list</h3>
              </li>
              <p className="mb-8">Our AI + human team creates an interactive list with initial quotes.</p>

              <li className="mb-4 flex items-center space-x-4">
                <MessagesSquare className="text-darkpurple h-8 w-8 flex-shrink-0" />
                <h3 className="text-xl font-semibold">Communicate with the venue</h3>
              </li>
              <p className="mb-8">
                Start the conversation with venues and leverage your dedicated team for negotiation.
              </p>
            </CardContent>
            <CardFooter>
              <Button className="w-full" onClick={() => router.push("/create/request")}>
                Get Started
              </Button>
            </CardFooter>
          </Card>

          <Card className=" bg-gray-200">
            <CardHeader>
              <CardTitle className="text-center text-3xl font-bold">Create an Event</CardTitle>
              <CardDescription className="text-center text-lg">If you want to level up your events</CardDescription>
            </CardHeader>
            <CardContent>
              <li className="mb-4 flex items-center space-x-4">
                <PartyPopper className="text-darkpurple h-8 w-8 flex-shrink-0" />
                <h3 className="text-xl font-semibold">Bring your community IRL</h3>
              </li>
              <p className="text mb-8">Scale your community events with volunteer hosting and recurring dinners.</p>

              <li className="mb-4 flex items-center space-x-4">
                <Handshake className="text-darkpurple h-8 w-8 flex-shrink-0" />
                <h3 className="text-xl font-semibold">Create genuine connections</h3>
              </li>
              <p className="text mb-8">Let our AI play matchmaker at your events for personalized networking.</p>

              <li className="mb-4 flex items-center space-x-4">
                <FaChartColumn className="text-darkpurple h-8 w-8 flex-shrink-0" />
                <h3 className="text-xl font-semibold">Make your events impactful</h3>
              </li>
              <p className="text mb-8">Leverage our network of partners from vendors, event hosts, and experiences.</p>
            </CardContent>
            <CardFooter>
              <Button
                className="w-full"
                onClick={() => {
                  router.push("/create");
                }}
              >
                Create Event
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
